<template>
  <v-card>
    <v-app-bar flat color="grey lighten-3" :dense="false" :fixed="false">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <span class="subheading mr-2" v-if="$vuetify.breakpoint.smAndUp"
        >Jahr:</span
      >

      <v-select
        :items="meta.years"
        v-model="selected_year"
        solo
        flat
        hide-no-data
        hide-details
        class="year-select"
        :dense="!toolbarIsSticky"
        v-if="$vuetify.breakpoint.smAndUp || !searchActive"
      />

      <v-spacer />

      <event-display-settings-dialog />

      <v-btn
        v-if="hasRole('manager') && $vuetify.breakpoint.smAndUp"
        :to="{ name: 'EventEdit', params: { id: 'new' } }"
        icon
        dark
        color="primary"
        data-cy="add-event"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon text><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="hasRole('manager') && !$vuetify.breakpoint.smAndUp"
            :to="{ name: 'EventEdit', params: { id: 'new' } }"
          >
            <v-list-item-title>Neue Tour</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportEvents">
            <v-list-item-title>Touren exportieren</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-progress-linear v-if="loading" :indeterminate="true" />

    <v-calendar
      v-if="!loading"
      ref="calendar"
      v-model="caledarValue"
      :events="displayEvents"
      event-name="title"
      type="month"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      event-overlap-mode="stack"
    >
    </v-calendar>
  </v-card>
</template>

<script>
import { mapState } from 'pinia'

import downloadService from '@/services/download'
import { handleApiError } from '@/utils.js'
import EventDisplaySettingsDialog from '@/components/events/EventDisplaySettingsDialog.vue'
import { useUserStore } from '@/stores/user'
import { useEventStore } from '@/stores/event'
import { useAuthStore } from '@/stores/auth'


function dmyToymd(dmy) {
  const parts = dmy.split('.')
  return `${parts[2]}-${parts[1]}-${parts[0]}`
}

export default {
  name: 'Events',
  components: { EventDisplaySettingsDialog },
  props: {},
  data() {
    return {
      caledarValue: '',
      loading: false,
      selected_year: (new Date()).getFullYear(),
      toolbarSwitchEnabled: true,
      toolbarIsSticky: false,
      lastScroll: null,
    }
  },
  computed: {
    displayEvents() {
      const events = this.getByYear(this.selected_year)
      return events.map(event => {
        return {
          ...event,
          start: dmyToymd(event.start_date),
          end: dmyToymd(event.end_date),
          timed: false,
          color: this.meta.organisations.find((e) => e.code === event.organisation).color,
        }
      })
    },
    ...mapState(useUserStore, { eventFilter: store => store.settings.eventFilter }),
    ...mapState(useEventStore, ['loaded_year', 'meta', 'getByYear']),
    ...mapState(useAuthStore, ['hasRole']),
  },
  watch: {
    selected_year(newVal) {
      this.$router.push({ name: this.$route.name, params: { year: newVal } }).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      })
      this.updateEvents()
    },
    eventFilter: {
      handler(val) { this.updateEvents() },
      deep: true,
    },
  },
  mounted() {
    this.selected_year = parseInt(this.$route.params.year)
    this.updateEvents()
  },
  methods: {
    updateEvents() {
      this.loading = true
      useEventStore().fetchEvents({ year: this.selected_year, filters: this.eventFilter })
        .then(() => {
          this.loading = false
          this.$root.$emit('triggerScroll')
        }).catch(error => {
          handleApiError(error, 'Fehler beim Laden der Daten')
        })
    },
    exportEvents() {
      downloadService.download('/export/events/excel',
        { year: this.selected_year, ...this.eventFilter },
      ).catch(error => {
        handleApiError(error, 'Fehler beim Laden der Datei')
      })
    },
  },
}
</script>

<style>
.sticky-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.year-select {
  min-width: 85px;
  max-width: 110px;
  margin-right: 5px;
}
</style>
